<template>
  <div class="PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('recharge.default[3]')"
            left-arrow
            @click-left="$router.go(-1)"
    >
      <template #right>
        <van-icon name="./skinlscc/icon_list.png" size="25" @click="onClickRight"/>
      </template>
    </van-nav-bar>
    <div class="ScrollBox">
      <div class="box" v-if="pageState==0">
        <div>
          <h4 style="padding: 5px">
            {{$t('recharge.label[0]')}}
          </h4>
        <van-field readonly is-link
                   v-model="bankInfo.coin_type"
                   @click="showPicker = true"
                   :placeholder="$t('recharge.placeholder[0]')"
        />
        <van-popup v-model:show="showPicker" round position="bottom">
          <van-picker show-toolbar :confirm-button-text="$t('common[2]')"
                      :cancel-button-text="$t('common[3]')"
                  :columns="columns"
                  @cancel="showPicker = false"
                      @change="onChangeType"
                  @confirm="onConfirmType"
          >
            <template #option="option">
              <div :style="option==coinType?'color: var(--button_bg)':''">
              <span style="font-weight: bold"> {{option}}</span>
              </div>
            </template>
          </van-picker>
        </van-popup>
          <h4 style="padding: 5px">
            {{$t('recharge.label[1]')}}
          </h4>
        <div style="line-height: 40px">
        <van-field readonly
                v-model="bankInfo.coin_address"
                   right-icon="./skinlscc/copy.png"
                :placeholder="$t('bindAccount.placeholder[1]')"
        >
          <van-icon @click="copyCode" name="./skinlscc/copy.png" class="copy-qb" slot="right-icon"
                    size="18"/>
        </van-field>
        </div>
        <textarea id="inputBox" style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"></textarea>
        <div class="QRCode">
          <img :src="bankInfo.qr_code" />
        </div>
        </div>
        <h4 style="padding: 5px">
          {{$t('recharge.label[3]')}}({{bankInfo.coin_type}})
        </h4>
        <div class="Panel" style="display: flex;padding: 10px">
            <van-field v-model="coinInfo.money0" placeholder="0" style="width: 180px" />
            <div style="font-size: 14px;margin-bottom: 10px;margin-left: 10px;color: var(--font_subtitle)">
            <div>
              ≈ <span style="color:var(--button_bg2)">{{usdtMoney}} USDT</span>
            </div>
            <div>
              ≈ <span style="color:#61D8F6">{{vndMoney}} {{InitData.usdtinfo.sign}}</span>
            </div>
            </div>
        </div>
        <h4 style="padding: 5px">
          {{$t('recharge.label[4]')}}
        </h4>
        <div class="">
        <div class="mygrid22" style="text-align: center">
            <van-uploader v-model="coinInfo.screenshots" max-count="1"
                          :after-read="afterRead">
              <van-icon name="./skinlscc/icon_upload2.png" size="40"/>
              <div style="margin-top: 10px"> {{$t('recharge5[0]')}}</div>
            </van-uploader>
        </div>
        </div>
        <div class="centerPanel" style="padding: 10px 0px">
          <van-button @click="submitRecharge">{{$t('recharge5[3]')}}</van-button>
        </div>
        <div style="margin-top: 15px;color: var(--font_subtitle);text-align: left;padding-bottom: 20px">
          <div style="margin-left: 20px">{{$t('recharge4[0]')}}</div>
        </div>

      </div>
      <div class="completePanel" v-if="pageState==1">
        <div class="semi-circle_down semi-circle_down2" style="margin-top: -15px"></div>
        <div>
          <img src="../../../public/skinlscc/btn_done.png" style="width:50px"/>
        </div>
        <div style="margin: 10px auto;font-size: 22px">{{$t('recharge5[1]')}}</div>
        <div>{{coinInfo.money}}{{$t('recharge5[2]')}}</div>

        <div class="centerPanel" style="padding: 10px 0px">
          <van-button to="/user" style="text-align: center">
            <span style="font-size: 18px">{{ $t('sell4[5]') }}</span>
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $Dialog from "../../common/Dialog";

export default {
  name: "mixRecharge",
  components: {},
  props: ["walletType"],
  data() {
    return {
      showPicker: false,
      columns:[],
      pageState:0,
      rechargeinfo:[],
      bankInfo:{},
      coinPrices_usdt:{},
      coinFix_usdt:{},
      coinPrices:{},
      currPrice:0,
      coinType:null,
      coinInfo: {
        //充币信息
        address: "",
        money: 0,
        screenshots: [],
      },
    };
  },
  computed: {
    cardList() {
      return data;
    },
    usdtMoney(){
      //1.03
      debugger
      if (!this.coinInfo.money0)
        return 0;
      return parseFloat(this.coinPrices_usdt[this.coinType]*this.coinInfo.money0).toFixed(4);
    },
    vndMoney(){
      if (!this.coinInfo.money0)
        return 0;
      return parseFloat(this.currPrice*this.coinInfo.money0).toFixed(2);
    },
  },
  watch: {},
  created() {
    this.$toast.loading({
      message: this.$t("dialog[7]"),
      duration: 4000,
    });

    this.$Model.GetRechargeData((data) => {
      let currlist = data.currencylist;
      let types = data.rechargetype;
      this.rechargeinfo = types;
      this.coinType = types[0]['coin_type'];
      for(var i=0;i<types.length;i++){
        let tt = types[i];
        let ctype = tt['coin_type'];
        this.columns.push(ctype);
        for (var k=0;k<currlist.length;k++){
          var kcurr = currlist[k];
          if (kcurr['name']==tt['bank_name']){
            this.coinPrices[ctype] = kcurr['price1'];
            this.coinPrices_usdt[ctype] = kcurr['price_usdt'];
            this.coinFix_usdt[ctype] = kcurr['fix_usdt'];
            break;
          }
        }
      }
      debugger
      this.bankInfo = types[0];
      this.currPrice = this.coinPrices[types[0]['coin_type']];
      this.coinInfo.money = 0;
      this.uuPrice = currlist[2]['price']
      this.$Dialog.Close();
    });
  },
  mounted() {
    // this.getQRUrl();
  },
  activated() {},
  destroyed() {
  },
  methods: {
    onClickRight(){
      this.$router.push("/user/rechargeList");
    },
    //复制码和金额
    copyCode() {
      var copyBox = document.getElementById("inputBox");
      copyBox.value = this.bankInfo.coin_address;
      copyBox.select();
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$Dialog.Toast(this.$t('recharge.label[5]'));
    },
    onChangeType(picker,obj,index){
      this.coinType = obj;
    },
    onConfirmType(obj,index){
      this.coinType = obj;
      this.bankInfo = this.rechargeinfo[index];
      this.currPrice = this.coinPrices[obj];
      this.showPicker = false
    },
    submitRecharge() {
      var money0 = this.coinInfo.money0;
      if (money0<=0){
        let msg = this.$t('recharge2[0]');
        this.$Dialog.Toast(msg);
        return;
      }else if (this.coinInfo.screenshots.length<=0){
        let msg = this.$t('recharge2[1]');
        this.$Dialog.Toast(msg);
        return;
      }
      this.coinInfo['coin_type'] = this.bankInfo.coin_type;
      this.coinInfo['address'] = this.bankInfo.coin_address;
      let postData = this.coinInfo;
      postData['money0'] = money0;
      var usdtMM = parseFloat(this.coinPrices_usdt[this.coinType]*money0).toFixed(4);
      postData['money'] = usdtMM;
      var vndMM = parseFloat(this.currPrice*money0).toFixed(4);
      postData['money2'] = vndMM;
      // postData['money'] = this.InitData.usdtdata[this.bankInfo.coin_type]*money0
      this.$Model.RechargeOrder(postData, (data) => {
        let msg = '';
        switch (data.code) {
          case 1:
            msg = this.$t('common3[0]');
            break;
          case -1:
            msg = this.$t('withdraw2[6]');
            break;
          default:
            if (data.code_dec){
              msg = data.code_dec;
            }
            break;
        }
        $Dialog.Close();
        if (data.code != 1) {
          this.$Dialog.Toast(msg);
        }else {
          this.$Model.GetRechargeRecord({},(data) => {
            if (data.info&&data.info.length>=1){
              this.pageState =1;
            }else{
              this.$router.push("/user/bindAccount");
            }
          });

        }
      });
    },
    afterRead2(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);

      //在这块创建FormData对象
      // FormData 对象的使用：
      // 1.用一些键值对来模拟一系列表单控件：即把form中所有表单元素的name与value组装成一个queryString
      // 2. 异步上传二进制文件。
      //上传图片需要转换二进制这里要用到FormData
      const forms = new FormData();
      //这里的file表示给后台传的属性名字，这里看接口文档需要传的的属性
      forms.append("image", file.file); // 获取上传图片信息

      //向后端发送相应的请求
      //这块的url是具体的交互接口
      //headers是上传图片需要用到的响应头，此处的token是后端那边给设置的，所以我请求的时候需要带上token，
      //token根据实际情况自行添加
      axios.post('User/uploadImage', forms, {
            headers: {
              "content-type": "multipart/form-data",
              token: localStorage["Token"],
            },
          })
          .then((data) => {
            if (data.code == 1) {
              file.message = this.$t("upload[2]");
              file.status = "success";
              file.url = data.url;
              // this.submitTask();
              //显示提现弹窗
              console.log("upload success");
            } else {
              file.status = "failed";
              console.log("upload false");
              // file.message = this.$t("upload[3]");
            }
          });
    },
    afterRead(file) {
      file.status = 'uploading'
      file.message = this.$t('upload[0]')
      this.uploadImgs(file)
    },
    uploadImgs(file) {
      if (!file.file.type.match(/image/)) {
        file.status = "failed";
        file.message = this.$t("upload[1]");
        return;
      }
      console.log(2);
      this.compressImg(file);
    },
    compressImg(file) {
      this.$Util.CompressImg(file.file.type, file.content, 750, (image) => {
        let param = new FormData();
        param.append("token", localStorage["Token"]);
        param.append("type", 3);
        param.append("image", image, file.file.name);
        this.$Model.UploadImg(param, (data) => {
          if (data.code == 1) {
            file.message = this.$t("upload[2]");
            file.status = "success";
            file.url = data.url;
            // this.submitTask();
            //显示提现弹窗
            console.log("upload success");
          } else {
            file.status = "failed";
            console.log("upload false");
            // file.message = this.$t("upload[3]");
          }
        });
      });
    },
  },
};
</script>

<style scoped>
  /* .van-nav-bar--fixed,.Site .van-nav-bar{
      background-color: #4e51bf;
  } */
  .PageBox {
    font-size: 13px;
  }

  .grid00{
    border-radius: 5px;

    padding: 5px;
  }
  .activePicker {
    color: red;
  }
  .van-cell {
    border-radius: 5px;
    overflow: hidden;
    margin-top: 5px;
    padding: 0 0px 0 13px;
    height: 35px;
    background-color: var(--panel_color);
  }

  .PageBox .van-cell>>>.van-cell__title{
    font-size: 15px;
    font-weight: bold;
  }

  .van-cell >>> .van-field__control {
    color: var(--cell_title) !important;
    padding-left: 7px;
    height: 35px;
  }

  .PageBox .box {
    width: 95%;
    padding: 0 9px;
    font-size: 16px;
    margin-top: 15px;
    border-radius: 10px;
    margin-left: 10px;
    background-color: transparent;
  }

  .mygrid22{
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: var(--panel_color);
    overflow: hidden;
    width: 100%;
    position: relative;
    z-index: 2;
    padding: 20px;
  }

  .information {
    display: flex;
    font-size: 18px;
    align-items: center;
    margin-bottom: 26px;
  }

  .information input {
    flex: 1;
    height: 40px;
  }

  .completePanel {
    margin: 0 auto;
    margin-top: 50px;
    text-align: center;
    padding: 10px;
  }

  .QRBox .upload .btn {
    padding: 10px 30px;
    border-radius: 6px;
    background-color: #4e51bf;
    color: #fff;
    text-align: center;
    margin: 41px 0 70px 0;
  }
  .van-loading {
    position: fixed;
    z-index: 99999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .infoBox >>> .van-cell-group::after {
    content: none;
  }

  .van-button__text {
    white-space: nowrap;
  }

  .tool {
    display: flex;
    justify-content: center;
  }


  .withdraw img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    vertical-align: middle;
  }

  .recharge img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    vertical-align: middle;
  }

  .withdrawBg {
    background: #fff;
    color: #696969;
  }
  .rechargeBg {
    background: #4f51c0;
    color: #fff;
  }
  .van-cell--borderless{
    background-color: #13C465;
    color: #fff;
  }
  .btn {
    width: 95%;
    padding: 10px 10px;
    border-radius: 10px;
    background-color: var(--button_bg);
    color: #FFFEFF;
    font-size: 18px;
    text-align: center;
    margin: 5px 10px 10px;
  }
</style>
